body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* header */
.navbar-nav .nav-link {
  
  font-size: 15px!important;
    padding: 10px 15px!important;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    position: relative;
    border-width: 0 0 0 1px;
    text-transform: capitalize;
}
.nav-Links .active-link {
  
  text-decoration: none;
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 600;
  padding: 10px 15px!important;
  position: relative;
  animation: linkspan ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

}
.nav-Links .active-link::before {
    position: absolute;
    content: "";
    width: 80%;
    height: 3px;
    background-color: white;
    left: 0px;
    right: 0;
    margin: 0 auto;
    border-radius: 10px;
    bottom: -5px;
}
/* about========================= */

.about-imp {
  text-align: center;
}
.about-imp img {
  width: 410px;
}
.img-content p {
  color: #ffffffc7;
  font-weight: 400;
  margin-bottom: 12px;
  font-size: 17px;
  transition: all linear 0.1s;
  width: fit-content;
}
.footer_links.img-content p:hover{
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

.img-content p span{
  color: #ffffff;
}

.img-content li {
  font-weight: 400;
  margin-bottom: 1rem;
  color: #636363;
    font-size: 17px;
}

.img-content h3 {
  color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 25px;
}

.border-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 40px 0;
}

.about-content-row {
  display: flex;
}

.about-content-row-2 {
  display: flex;
}

.ml-20 {
  margin-left: 20px;
}

/* footer-section================================================== */

.footer-section {
  /* margin-top: 5rem; */
  background-color: #4d73e2;
  /* padding-bottom: 3rem; */
  padding: 50px 0;
}

.footer-logo{
  margin-top: 1.5rem !important;
}

.footer-logo img {
  width: 190px;
  margin-bottom: 25px;
}

.footer-content {
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.usefull-link{
  padding-left: 90px !important;
}

.footer-logo p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffffc7;
  margin-bottom: 12px;
}

.link-contant {
  color: whitesmoke;
  text-decoration: none !important;
}



/* ===================================>Animation<=================================== */

.banner-heading {
  animation: star 15s infinite !important;
  transform-origin: 70% 70%;
}

.banner-sub-heading {
  animation: backInUp 15s infinite !important;
}

@keyframes star {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.75);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes backInUp {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.75);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}


/* =========================================>Piyush<========================================= */

/* =========================================>Home<========================================= */

.margin-left-100{
  margin-left: 80px;
}


/* ==================================>Brands_Container<================================== */

.brands_container .skilslid{
  width: 80px !important;
}

.brands_container .slick-prev:before, .brands_container .slick-next:before{
  color: #ffffff !important;
}

.brands_container .slick-next{
  right: 47%;
}

.brands_container .slick-prev{
  left: 47%;
}

.brands_container .slick-prev:before, .brands_container .slick-next:before{
  top: 75px !important;
}

/* =========================================>costom btn<========================================= */

.btn4d73e2-disable {
  text-decoration: none !important;
  padding: 8px 30px;
  border: 1px solid #4d73e2;
  position: relative;
  overflow: hidden;
  outline: none;
  color: white;
  font-size: 17px;
  border-radius: 10px;
  z-index: 55;
  font-weight: 600;
  transition: 0.3s;
  background: #4d73e2;
}

.btn4d73e2-active{
  text-decoration: none !important;
  padding: 8px 30px;
  border: 1px solid #4d73e2;
  position: relative;
  overflow: hidden;
  outline: none;
  font-size: 17px;
  border-radius: 10px;
  z-index: 55;
  font-weight: 600;
  transition: 0.3s;
  background: #4d73e2;
  color: #ffffff;
}

.modal-content{
  background-image: url(./img/popup-background.png) !important;
}


.brands_container .slick-initialized .slick-slide{
  text-align: center;
}
.title-theme-head{
  color: #1e1e1e;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
}
.title-theme-p{
  color: #636363;
  font-size: 17px;
  font-weight: 400;
}

.demo-h1{
  font-size: 10px;
}

.img-content {
  padding-left: 1rem;
}
.set-diwalievent{
  width: 50%;
}

#toggle-btn-close {
  display: none;
}
