@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.bg-home {
  /* background-image: linear-gradient(90deg,#f55f8d 0,#f8ae56 145%,#f55f8d); */
  min-height: 100vh;
  width: 100%;
  background-color: #4d73e2;
  padding-top: 200px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #4d73e2 !important;
  z-index: 555 !important;
  transition: 0.3s;
}

.header-shadow {
  box-shadow: rgb(0 0 0 / 45%) 0px 0px 7px;
}

.header-button {
  background-color: white;
  color: #5974f0;
  padding: 12px 25px;
  border-radius: 10px;
  margin-right: 25px;
  margin-top: 30px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.header-button:hover {
  background: #ffffff3b;
}

.responsive-navbar-nav{
  animation-name: slow;
}
@keyframes slow{
  
}
.btn-join-now1 {
  margin-top: 30px;
  padding: 8px 30px;
  border: 1px solid #ffffff;
  position: relative;
  overflow: hidden;
  outline: none;
  background: #fff;
  border-radius: 50px;
  z-index: 55;
  font-weight: 600;
  transition: 0.3s;
  padding: 12px 35px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.btn-join-now1::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0%;
  background: #4d73e2;
  transition: 0.3s;
  z-index: -1;
}

.btn-join-now1:hover {
  color: #fff;
  background: #4d73e2;
  border: 1px solid #fff;
}

.btn-join-now1:hover:after {
  left: 0;
  width: 100%;
}

/* ==================================>hero-component<================================== */

.welcome {
  background: #ffffff3b;
  padding: 12px 25px;
  border-radius: 10px;
  font-weight: 600;
  color: #ffffff;
}

.separator_off {
  font-family: "Roboto", sans-serif;
  font-size: 45px;
  line-height: 55px;
  font-weight: 700;
  margin-bottom: 10px;
}

.subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #ffffffd9;
  margin-bottom: 35px;
}

.brands_container {
  width: 100%;
  margin-top: 100px;
  padding-bottom: 20px;
}

.brands {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
  position: relative;
}

.brand-logo {
  width: 199.167px;
}

.brand-logo img {
  width: auto;
  height: auto;
  transition: 0.5s;
}

.prev,
.next {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  transition: 0.5s;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #5974f0;
  font-weight: 900;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.brands_container:hover .prev {
  left: -50px;
  opacity: 1;
}

.brands_container:hover .next {
  right: -50px;
  opacity: 1;
}

.prev:hover,
.next:hover {
  background: #4d73e23b;
  color: white;
  cursor: pointer;
}
.section-space
{
  margin: 100px 0;
}
.title-space
{
  margin-bottom: 100px;
}
.title-top {
  background: #4d73e23b;
  padding: 10px 30px;
  border-radius: 10px;
  margin: 0 auto;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  color: #4d73e2;
  margin-bottom: 30px;
  letter-spacing: 2px;
}

.our-services {
  margin-top: 70px;
  padding: 40px 0;
  background-image: url(../img/bg4.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.title {
  margin-bottom: 20px;
  font-size: 41px;
  color: #1e1e1e;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: 48px;
}

.text {
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin: 10px 0 50px 0;
  color: #636363;
  text-align: center;
  /* margin-bottom: 75px; */
}

.services-slider.services-slider-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border: 1px solid #8988881c;
  height: 355px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 57px;
}

.services-slider.services-slider-card:hover {
  background: #fff;
  box-shadow: 0 5px 70px rgb(38 42 76 / 10%);
  border-color: transparent !important;
}
.services-inner-img img{
  width: 100%;
}
.btn-join-now {
  text-decoration: none !important;
  padding: 8px 30px;
  border: 1px solid #4d73e2;
  position: relative;
  overflow: hidden;
  outline: none;
  font-size: 17px;
  background: #fff;
  border-radius: 10px;
  z-index: 55;
  font-weight: 600;
  transition: 0.3s;
}

.btn-join-now::after {
  text-decoration: none !important;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0%;
  background: #4d73e2;
  transition: 0.3s;
  z-index: -1;
}

.btn-join-now:hover {
  text-decoration: none !important;
  color: #fff;
  border-color: #4d73e2;
  border: 1px solid #fff;
}

.btn-join-now:hover:after {
  text-decoration: none !important;
  left: 0;
  width: 100%;
}

.our-services .slider {
  margin: 50px 0;
}


/* ..............................aboutus.............................. */
.about_us {
  margin: 100px 0 !important;
}

/* .about_us_left{
  width: 50vh;
} */

.about_us_left img {
  border-radius: 0 80px 80px 0;
}

.about_us_right p {
  width: 75%;
  font-family: "Roboto", sans-serif;
  color: #636363;
  text-align: left;
  margin-bottom: 30px;
}

.about_us_right_list li {
  padding: 8px 5px 8px 35px;
  list-style: none;
  padding-bottom: 15px;
  font-family: "Roboto", sans-serif;
  color: #636363;
  position: relative;
}

.about_us_right_list li::after {
  position: absolute;
  content: '';
  left: 0;
  top: 8px;
  background-image: url(/src/img/checked.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 23px;
  height: 23px;
}

/* ..............................over-aboutus.............................. */

/* ..............................features.............................. */
.features {
  margin: 100px 0 !important;
}

.features-right-data {
  align-items: center;
}

.features-right-info h3 {
  font-size: 24px;
  font-weight: 700;
}

.features-right-info p {
  color: #636363;
  font-size: 17px;
}

.set-features-images {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.inner-image {
  position: absolute;
  left: 44px;
  top: 44px;
  width: 46px;
}
.features-bg-img{
  height: 135px;
}
.features-left .title-top{
  margin: 0px;
}
/* ..............................over-features.............................. */

/* ..............................counter.............................. */
.counter {
  margin: 50px 0 50px 0;
}

.counter-info {
  text-align: center;
}

.counter-info h1 {
  font-weight: 700;
  color: #4d73e2;
}

.counter-info img {
  width: 50px;
}

.counter-info h3 {
  font-size: 20px;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 20px;
}
.our-strangth{
  margin-bottom: 60px;
}

/* ..............................over-counter.............................. */

.cards {
  width: 274.66px;
  height: 339.39px;
  background: #fff;
  box-shadow: 0 20px 70px rgb(38 42 76 / 20%);
  /* margin: 0 auto; */
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  flex-direction: column;
  padding: 50px 40px 45px;
  justify-content: space-evenly;
}

.cards .head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 75px;
}

.cards .head .text h5,
.cards .head .text p {
  margin: 0;
}

.cards .head .text h5 {
  font-size: 16px;
  color: #1e266d;
  font-weight: 700;
}

.cards .head .text p {
  font-size: 15px;
  color: #acacac;
  font-weight: 400;
}

.cards .head img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.cards .work-msg {
  font-size: 18px;
  color: #1e266d;
}

.cards .comment {
  font-size: 15px;
  color: #acacac;
}

.cards .rating {
  font-size: 15px;
  color: #f5a004;
  display: flex;
  column-gap: 10px;
  justify-content: center;
}

.my-card {
  background-image: url(../img/bg2.png);
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.my-card-container {
  /* display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr); */
  flex-wrap: wrap;
  gap: 30px;
  display: flex;
  justify-content: center;
}

.my-card-container .cards:nth-child(even) {
  /* grid-row: 1/-1; */
  margin-top: 150px;
}

/* .my-card-container .cards:nth-child(1) {
  grid-row: 1/-1;
}

.my-card-container .cards:nth-child(2) {
  grid-row: 1/-1;
  margin-top: 150px;
}

.my-card-container .cards:nth-child(6) {
  grid-row: 1/-1;
  margin-top: 150px;
  grid-column: 6/7;
}

.my-card-container .cards:nth-child(4) {
  grid-column: 4/5;
  grid-row: 1/-1;
  margin-top: 150px;
} */

/* .test{
 r
} */
/* .slick-prev {
  left: -75px;
} */
.footer-section {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  z-index: -1;
}