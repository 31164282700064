/* ================================>Navbar<================================ */


@media only screen and (max-width:1200px) {

    #toggle-btn-close {
        display: block;
    }
    
    .nav-link {
        margin: 10px 0px;
    }

    .min-nav .contact {
        order: 4;
        /* float: right;
        margin: 5px 0px; */
        /* width: 10px; */
    }

    .min-nav .toggle-btn {
        /* order: 4; */
        /* margin: 0px 10px !important; */
    }

    .navbar-light .navbar-toggler {
        margin-right: 0px;
    }

    .call-us {
        margin-right: 0px;
        float: right;
        margin-left: auto;
    }


    @keyframes linkspan {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }

}

@media(max-width:340px) {
    .nav-link {
        padding: 0px 10px !important;
    }

    .call-us-text {
        font-size: 28px !important;
    }

    .call-us .call-us-number {
        margin-right: 10px !important;
        font-size: 28px !important;
    }
}

@media(max-width:660px) {
    .call-us-text {
        font-size: 28px !important;
    }

    .call-us .call-us-number {
        font-size: 28px !important;
    }
}


/* ========================================>Home Page<======================================== */

@media(max-width:365px) {
    .banner-sub-heading {
        text-align: center;
        padding-right: 10px;
        font-size: 1em;
    }

}

@media(max-width:471px) {
    .we-work-content {
        display: block;
    }

    .we-work-card {
        margin: 20px auto;
    }

    .services-slider {
        width: 270px !important;
    }
}

@media (max-width:574px) {
    .banner-heading {
        font-size: 2em;
        padding-top: 75%;
    }

    .banner-sub-heading {
        padding-right: 10px;
        font-size: 1em;
    }

    /* .navbar-light .navbar-toggler{
        margin-right: 60px !important;
    } */
}

@media (max-width:991px) {
    .hiring-developer-content h1 {
        font-size: 35px;
    }
}

@media (max-width:450px) {
    .brands_container .slick-prev {
        display: none !important;
    }

    .brands_container .slick-next {
        display: none !important;
    }

    .hiring-developer-content h1 {
        font-size: 28px;
    }
}


@media (min-width:450px) and (max-width:768px) {
    .slick-prev {
        left: 25px !important;
    }

    .slick-next {
        right: 25px !important;
    }
}

/* ============================>Service Page<============================ */

@media(max-width:574px) {
    .card-container, .front, .back {
        width: 18rem;
        height: 24.5rem;
    }

    .front img {
        width: 18rem;
        height: 21rem;
    }

    .front h3 {
        font-size: 20px;
    }

    .back li {
        font-size: 18px;
    }

    .border-content {
        margin: 40px 0;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .card-container, .front, .back {
        width: 18rem;
        height: 24.5rem;
    }

    .front img {
        width: 18rem;
        height: 21rem;
    }

    .front h3 {
        font-size: 20px;
    }

    .back li {
        font-size: 18px;
    }
}

/* ========================>About Page<======================== */

@media (min-width:991px) and (max-width:1199px) {
    .img-content p {
        font-size: 1rem;
    }
}

@media (max-width:991px) {
    .about-content-row-2 {
        flex-direction: column-reverse !important;
    }

    .about-content-row {
        flex-direction: column;
    }

    .about-imp {
        margin-top: 30px;
    }

    .ml-20 {
        margin-left: 0px !important;
    }
}

@media (max-width:370px) {
    .about-imp img {
        width: 300px;
    }
}

@media (min-width:370px) and (max-width:470px) {
    .about-imp img {
        width: 350px;
    }
}

/* =====================================>Cnontact Page<===================================== */

@media (max-width:768px) {
    .contact-address {
        padding: 0 15px !important;
        margin-top: 35px !important;
    }

    .contact-send-btn {
        margin-top: 30px;
    }
}

/* =======================>Our-Portfolio<======================= */

/* @media (max-width:768px) {
    .buttonaction {
        font-size: 16px;
    }

    .buttonaction {
        font-size: 16px;
    }

} */

@media (min-width:351px) and (max-width:450px) {
    .tab-img {
        width: 20rem;
        height: 18rem;
    }

    .tab-img img {
        width: 20rem;
        height: 18rem;
    }
}

@media (max-width:350px) {
    .tab-img {
        width: 15rem;
        height: 15rem;
    }

    .tab-img img {
        width: 15rem;
        height: 15rem;
    }
}


/* =================================>Life7seasol<================================= */

@media (min-width:991px) and (max-width:1400px) {
    .desc {
        width: 500px;
    }

    .imgs-content img {
        width: 500px;
        height: 500px;
    }
}

@media (max-width:1200px) {
    .parent-desc:nth-child(n+0) {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
    }

    .desc {
        margin: 20 auto;
    }

    /* 
    .main-event {
        display: block;
    } */

    .imgs-content {
        margin: 0 auto;
    }
}

@media (min-width:550px) and (max-width:768px) {
    .desc {
        width: 500px;
    }

    .imgs-content img {
        width: 500px;
        height: 500px;
    }

    .main-event {
        margin-top: 100px;
    }
}

@media (min-width:450px) and (max-width:550px) {
    .desc {
        width: 400px;
    }

    .imgs-content img {
        width: 400px;
        height: 400px;
    }

    .main-event {
        margin-top: 80px;
    }
}

@media (max-width:450px) {
    .desc {
        width: 280px;
    }

    .imgs-content img {
        width: 280px;
        height: 280px;
    }

    .main-event {
        margin-top: 50px;
    }
}

/* =================================>Footer<================================= */

@media (max-width:575px) {
    .usefull-link {
        padding-left: 10px !important;
    }
}

@media (max-width:1200px) {
    .footer-logo img {
        width: 250px;
        margin-bottom: 10px;
    }
}



/* ================================>Redesign<================================ */

@media only screen and (max-width:1200px) {

    .separator_off {
        font-size: 36px;
        line-height: 50px;
    }

    .subtitle {
        font-size: 16px;
    }

    .brands_container .slick-next {
        right: 45%;
    }

    .brands_container .slick-prev {
        left: 45%;
    }

    .about_us_left img {
        border-radius: 50px;
    }

    .title {
        font-size: 36px;
        line-height: 50px;
    }

    .service-bg {
        padding-top: 200px;
    }
    .services-img img{
        width: 100%;
    }
    .services-img{
        text-align: center;
    }
    .contact-input {
        padding: 13px 9px !important;
    }
    .android-img img {
        width: 28rem;
        height: 28rem;
    }
    .nav-Links .active-link::before {
        width: 97% !important; 
    }
}

@media only screen and (max-width:991px) {
    .usefull-link {
        padding-left: 10px !important;
    }

    .service-bg {
        height: 100%;
        padding-top: 110px;
    }
    .apply-job-announce {
        display: none;
    }

    .applyjob {
        text-align: center !important;
    }

    .applyjob .text {
        text-align: center !important;
    }

    .applyjob .title {
        text-align: center !important;
    }

    .footer-logo {
        margin-top: 0px !important;
    }

    .contact-input {
        padding: 10px 8px !important;
    }

    .tab-card {
        width: 50%;
    }
   .card-img .slick-arrow.slick-next{
        right: 55px;
      }
    .card-img .slick-arrow.slick-prev {
        left: 55px;
    }
    .card-info1{
        width: 100% !important;
    }
    .card-img{
        width: 100% !important;
        margin-bottom: 20px;
    }
    .footer-section {
        margin-top: 20px;
    }
    .brands_container .slick-slider button{
        display: none !important;
    }
    .header-content {
        text-align: center ;
    }
    .nav-Links .active-link::before {
        width: 95% !important; 
    }
    .events_cards p{
        margin: 0;
    }
    .services-img{
        text-align: center;
    }
    .services-img img{
        width: 70%;
    }
}

@media only screen and (max-width:768px) {
    .inner-image {
        position: absolute;
        left: 29%;
        top: 42px;
        width: 60px;
    }

    .features-bg-img {
        width: 140px;
    }

    .usefull-link {
        padding-left: 10px !important;
    }

    .service-bg {
        height: 80%;
    }
    .services-img{
        text-align: center;
    }
    .services-img img{
        width: 84%;
    }
    .text {
        font-size: 14px;
    }
    .footer-logo img {
        width: 190px;
        margin-bottom: 25px;
    }
    .img-content h3 {
        font-weight: 600;
    }
    .about_us {
        margin: 70px 0 !important;
    }
    .features {
        margin: 0px 0 !important;
      }
      .section-space {
        margin: 70px 0;
    }
    .counter {
        margin: 20px 0 50px 0;
    }
    .our-strangth{
        margin-bottom: 30px;
    }

    /* .title-top.margin-left-right {
        margin: 0 auto;
    } */
    .header-content {
        text-align: center;
    }
    .nav-Links .active-link::before {
        width: 95% !important; 
    }
    .card-info1 p{
        margin: 0;
    }
    .padding-bottom-80px {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width:574px) {

    .separator_off {
        font-size: 24px;
        line-height: 30px;
    }

    .title {
        font-size: 30px;
        line-height: 36px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
    }

    .header-content {
        text-align: center;
    }

    .welcome {
        margin-right: 0px;
        font-size: 12px;
    }

    .btn-join-now {
        font-size: 12px;
    }

    .skilslid {
        width: 65px !important;
    }

    .bg-home {
        min-height: 90%;
        padding-top: 150px;
        padding-bottom: 50px;
    }

    .features-right-info h3 {
        font-size: 18px;
        font-weight: 700;
    }

    .inner-image {
        position: absolute;
        left: 31%;
        top: 30px;
        width: 40px;
    }

    .features-bg-img {
        width: 100px;
    }
    .features-bg-img {
        height: 100px;
    }
    .features-right-info p {
        font-size: 14px;
    }

    .my-card-container .cards:nth-child(even) {
        /* grid-row: 1/-1; */
        margin-top: 0px;
    }

    .logo-img {
        width: 120px;
    }

    .service-bg {
        height: 74%;
    }

    .tab-img img {
        width: 100%;
        height: 100%;
    }

    .tab-img {
        width: 100%;
        height: 100%;
    }

    .tab-card {
        width: 80%;
        height: 14%;
        margin-bottom: 20px;
    }

    .tab-img {
        margin: 0px;
    }
    .card-info1 h2{
        font-size: 24px;
    }
    .card-info1 p{
        font-size: 14px;
    }
    .footer-section {
        margin-top: 20px;
      }
      .footer-logo img {
        width: 175px;
        margin-bottom: 38px;
    }
    .img-content p {
        margin-bottom: 9px;
        font-size: 14px;
    }
     .android-img img {
        width: 23rem;
        height: 23rem;
    }
    .services-inner-img{
        width: 100% !important;
        margin: 0 auto;
    } 
    .about-service p{
        padding-bottom: 0px;
    }
    .nav-Links .active-link::before {
        width: 95% !important; 
    }
    .events_cards {
        margin: 0px 0;
    }
    .events_cards p{
        margin: 0 !important;
    }
    .events_cards {
        margin: 50px 0;
    }
    .event-section-1 img {
        margin: 50px 0 50px 0;
    }
    .services-img img{
        width: 82%;
    }
    .services-img{
        text-align: center;
    }
    .services-slider-p{
        font-size: 14px !important;
    }
    .features-left .title-top {
        margin: 0 auto;
    }
    .services-bg {
        margin-bottom: 1rem;
        padding-top: 120px;
        height: 26vh;
    }
    .front img {
        height: 18rem;
    }
    .card-container, .front, .back {
        height: 22rem;
    }
    .back li {
        font-size: 16px;
    }
    .padding-bottom-80px {
        padding-bottom: 10px;
    }
    .brands_container {
        margin-top: 10px;
        padding-bottom: 10px;
    }
    .our-services {
        margin-top: 40px;
    }
    
}

@media only screen and (max-width:350px) {

    .separator_off {
        font-size: 24px;
        line-height: 26px;
    }

    .title {
        font-size: 22px;
        line-height: 26px;
    }

    .subtitle {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 20px;
    }

    .header-content {
        text-align: center;
    }

    .welcome {
        margin-right: 0px;
        font-size: 10px;
    }

    .btn-join-now {
        font-size: 10px;
    }

    .service-bg {
        height: 65%;
    }

    .tab-img img {
        width: 100%;
        height: 100%;
    }

    .tab-img {
        width: 100%;
        height: 100%;
    }

    .tab-card {
        width: 100%;
        height: 14%;
    }

    .tab-img {
        margin: 0px;
    }
    .life7seasol h3 {
        margin-bottom: 18px;
        line-height: 30px;
        font-size: 18px;
    }
    .event-section-1 img {
        border-radius: 6px;
        margin: 40px 0 60px 0;
    }
    .android-img img {
        width: 18rem;
        height: 18rem;
    }
    .services-inner-img{
        width: 100% !important;
    }
    .about-service p{
        padding-bottom: 0px;
    }
    .services-slider-p{
        font-size: 14px !important;
    }
    .counter-info{
        margin: 8px 0;
    }
    .service-bg img{
        width: 86% !important;
    }
    .nav-Links .active-link::before {
        width: 90% !important; 
    }
    .events_cards {
        margin: 0px 0;
    }
    .events_cards p{
        margin: 0;
    }
    .our-services {
        margin-top: 50px;
    }
    .padding-bottom-80px {
        padding-bottom: 25px;
    }
    .front img {
        height: 18rem;
    }
    .card-container, .front, .back {
        height: 22rem;
    }
    .back li {
        font-size: 16px;
    }
    .padding-bottom-80px {
        padding-bottom: 10px;
    }
    .brands_container {
        margin-top: 10px; 
        padding-bottom: 40px;
    }
}