.mt-30 {
  margin-top: 68px;
}

* {
  margin: 0;
  padding: 0px;
}

/* =========================Nav============================== */
.perent {
  background: white;
  /* margin-bottom: 450px; */
  overflow-x: hidden;
}

.nav-Links {
  margin-left: auto;
}

#basic-navbar-nav {
  /* align-items: center !important; */
}

.navbar {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.nav-link {
  text-decoration: none;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 500;
  padding: 0 20px !important;
  position: relative;
  animation: linkspan ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.min-nav {
  /* position: fixed; */
  background-color: #0b2242 !important;
}

.navbar-brand {
  margin: 0 0 0 20px !important;
}

/* .logo-text {
    padding: 0px !important;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(238, 64, 67) !important;
    text-decoration: none;
} */

.logo-img {
  width: 150px;
  cursor: pointer;
}

.navbar-toggler {
  box-shadow: none !important;
  border: none !important;
}

.navbar-light .navbar-toggler-icon{
  background-image: url(./img/menu.png) !important;
}

.navbar-light .navbar-toggler {
  /* margin-right: auto !important; */
  /* display: none !important; */
}

/* ==>

<== */

.nav-link:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 50%;
  height: 3px;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s;
  border-radius: 10px 0 0 10px;
}

.nav-link:after {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 0;
  left: 50%;
  height: 3px;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s;
  border-radius: 0 10px 10px 0;
}

.nav-link:hover:before {
  transform: scaleX(1);
}

.nav-link:hover:after {
  transform: scaleX(1);
}

/* .navbar-light .navbar-toggler{
    margin-right: 15px;
} */

.call-us {
  margin-right: 80px;
  float: right;
  margin-left: auto;
}

.call-us .call-us-number {
  margin-right: 50px !important;
  margin-bottom: 0px !important;
  font-size: 38px !important;
  color: #fff;
  line-height: 29px;
  font-weight: 600;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.call-us-text {
  margin-bottom: 0px !important;
  font-size: 38px !important;
  color: #fff;
  line-height: 29px;
  font-weight: 600;
  opacity: 0.6 !important;
  animation: setcallustext ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.min-nav .contact {
  order: 4;
}

.contact {
  position: absolute;
  top: 27px;
  right: 23px;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes linkspan {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes setcallustext {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

/* ==========================================>scrollbar<========================================== */

::-webkit-scrollbar {
  width: 3px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ========================================>Home Page<======================================== */

.home-background {
  background-image: url(./img/home.png);
  height: 80vh;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-heading {
  text-align: center;
  padding-top: 20%;
  font-size: 5em;
}

.banner-sub-heading {
  text-align: right;
  padding-right: 17%;
}

.our-focus-section {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.our-focus-section p {
  font-size: 20px;
  margin: auto;
  padding-top: 2rem;
}

.slider-setion {
  overflow-x: hidden;
}

.slider-setion .slider-img img {
  height: 100% !important;
}

.slider-setion .slick-dots {
  bottom: 40px !important;
}

.slider-setion .slick-dots li button:before {
  bottom: 70px !important;
  font-size: 12px !important;
  color: #fff !important;
}

/* .slick-dots li.slick-active button:before{
    color: #fff !important;
} */

/* 
.slider-setion .slick-next:before, .slick-prev:before {
    position: absolute !important;
    left: -80px !important;
} */

/* .slick-prev:before{
    position: absolute !important;
    right: -80px !important;
} */

.our-services {
  margin-top: 4em;
  text-align: center;
  padding-bottom: 40px;
}

.our-services h1 {
  font-size: 2.5rem;
  color: #000;
  font-weight: 600;
}

.services-slider {
  width: 330px !important;
  text-align: center;
}

.services-slider img {
  width: 70px;
  margin: 10px 0px;
  margin: 0 auto;
  padding-top: 15px;
}

.services-slider h2 {
  padding: 20px 0px 3px;
  font-size: 22px;
  font-weight: 500;
  color: #1e1e1e;
}

/* .services-slider p {
 opacity: 0.5;
} */
.we-work-head {
  margin-top: 70px;
  text-align: center;
  margin-bottom: 35px;
}

.we-work-head h3 {
  font-size: 2.5rem;
  color: #000;
  font-weight: 600;
}

.we-work-content {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.we-work-card {
  width: 180px;
  padding: 30px 0px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-transition: 0.6s;
  box-shadow: 0 10px 30px 0 rgb(12 46 96 / 10%);
  background-color: #fff;
  border-radius: 25px;
  transition: 0.6s;
  height: 100%;
  margin: 12px 18px;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.we-work-card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.we-work-card img {
  width: 55px;
  height: 55px;
}

.we-work-card p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-top: 10px;
  margin-bottom: 0%;
}

/* ==>card animation<=== */

/* =======================>Service Page<======================= */

.service-bg {
  background-color: #4d73e2;
  height: 70%;
  color: #fff;
  margin-bottom: 5rem;
  padding-top: 150px;
}
.services-bg {
  background-color: #4d73e2;
  height: 30vh;
  color: #fff;
  margin-bottom: 5rem;
  padding-top: 136px;
}
/* .service-bg .separator_off {
  color: #fff;
  margin-bottom: 0px;
  padding-top: 10px;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
} */

.about-service {
  text-align: center;
}

.about-service h3 {
  color: #000;
  margin-bottom: 20px;
}

/* .about-service P {
  margin-bottom: 50px;
} */

.about-card {
  /* margin-bottom: 50px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* ==>
<== */

.front img {
  width: 85%;
  height: 17rem;
}

.front h3 {
  background-color: #4d73e2;
  color: #fff;
  padding: 21px;
  margin: 0 !important;
  font-size: 17px;
}

.card-container,
.front,
.back {
  width: 21rem;
  height: 21rem;
}

.back {
  background-color: #0b2242;
  color: #fff;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.back li {
  font-size: 16px;
  margin: 0px 60px !important;
  text-align: left;
}

.back .our-source {
  margin-bottom: 30px;
}

/* .back .Viewmore-btn {
  text-decoration: none !important;
  background-color: transparent;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  font-size: 20px;
  padding: 10px 25px;
  border-radius: 5px;
  margin-top: 30px;
}

.back .Viewmore-btn:hover {
  background-color: #fff;
  color: #000;
  font-weight: 600;
} */

.front,
.back {
  border-radius: 4px;
  font-size: 2rem;
  text-align: center;
  border: 1px solid #00000061;
}

.card-container {
  position: relative;
  margin-bottom: 50px;
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  background-color: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  transition: transform 0.8s ease;
}

.back {
  transform: rotateY(180deg);
}

.card-container:hover .front {
  transform: rotateY(-180deg);
}
.our-source ul {
  padding-left: 0;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 400;
}
.card-container:hover .back {
  transform: rotateY(0deg);
}

.card-container {
  perspective: 75rem;
}

.hiring-developer-section {
  margin-top: 80px;
}

.hiring-developer-content {
  height: 262px;
  text-align: center;
  background-color: #0b2242;
  border-radius: 50px;
}

.hiring-developer-content h1 {
  color: #fff;
  font-size: 48px;
  padding-top: 55px;
  font-weight: 600;
  text-align: center;
}

.hiring-developer-content button {
  padding: 10px 35px;
  background-color: #fff;
  color: #000;
  border: none !important;
  box-shadow: none !important;
  font-weight: 700;
  font-size: 25px;
  border-radius: 50px;
  /* float: right;
    margin-right: 232px; */
  margin-top: 30px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Roboto" !important;
  color: #0b2242 !important;
  font-size: 40px !important;
  top: -50px !important;
  position: absolute !important;
}

/* =================================>about Page<================================= */

.about-bg {
  background-image: url(./img/About.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.about-bg h1 {
  color: #fff;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

/* ===========================>Contact Page<=========================== */

.contact-bg {
  background-image: url(./img/Contact.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.contact-bg h1 {
  color: #fff;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

.contact-input {
  padding: 15px 15px !important;
  box-shadow: none !important;
  margin-bottom: 15px !important;
}

/* .contact-send-btn {
  margin-top: 50px;
  padding: 15px 20px;
  border: none !important;
  background-color: #0b2242 !important;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
}

.contact-send-btn-disabled {
  opacity: 0.7;
  margin-top: 50px;
  padding: 15px 20px;
  border: none !important;
  background-color: #0b2242 !important;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
} */

/* .contact-close-btn {
  margin-top: 50px;
  padding: 15px 20px;
  border: 1px solid #0b2242 !important;
  background-color: transparent !important;
  color: #000;
  font-size: 20px;
  border-radius: 5px;
} */

.contact-address {
  padding: 0 70px !important;
}

.contact-number {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.connect-logos {
  display: flex;
}

.facebook-logo {
  margin-top: 10px;
  margin-right: 15px;
  width: 30px;
  cursor: pointer;
}

.address-email {
  color: #bdbec8;
  font-weight: 600;
}

.connect-map {
  margin-top: 50px;
}

.connect-map iframe {
  width: 100%;
  height: 250px;
}

/* ========================>Android Development<======================== */

.android-bg {
  background-image: url(./img/ANDROID-DEVELOPMENT-BG.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.android-bg h1 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

.android-img {
  text-align: center;
}

/* .android-img img {
  width: 35rem;
  height: 35rem;
} */

.android-text h2 {
  color: #000;
  margin-bottom: 20px;
}

.android-text ul {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  padding-left: 0px !important;
  list-style: none !important;
}

.android-text li {
  list-style: none !important;
  margin-bottom: 8px;
}

/* ==============================>Game Development<============================== */

.game-bg {
  background-image: url(./img/GAME-DEVELOPMENT-BG.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.game-bg h1 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

/* ==============================>Ios Development<============================== */

.ios-bg {
  background-image: url(./img/ISO-BG.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.ios-bg h1 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

/* ==============================>WEB Development<============================== */

.web-bg {
  background-image: url(./img/WEB-DEVLOPMENT-BG.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.web-bg h1 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

/* ==============================>SEO Development<============================== */

.seo-bg {
  background-image: url(./img/SEO-BG.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.seo-bg h1 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

/* ==============================>WEB-DESIGN Development<============================== */

.web-design-bg {
  background-image: url(./img/WEB-DESIGN-BG.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.web-design-bg h1 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

/* ==================================>LOADER<================================== */

.loader {
  position: relative;
  height: 100vh;
  background: #201e1e;
  z-index: 999999;
  overflow-x: hidden;
}

.loadercontent-right {
  content: "";
  background-color: transparent;
  background-color: #201e1e;
  width: 51%;
  height: 100vh;
  opacity: 0.7;
  position: absolute;
  top: 0%;
  right: 50%;
  animation: loderright 2s infinite;
  animation-timing-function: cubic-bezier(0.5, 0.53, 0.32, 0.29);
}

@keyframes loderright {
  from {
    left: 50%;
  }

  to {
    left: 100%;
  }
}

.loadercontent-left {
  content: "";
  background-color: transparent;
  background-color: #201e1e;
  width: 51%;
  height: 100vh;
  position: absolute;
  opacity: 0.7;
  top: 0%;
  left: 0%;
  animation: loderleft 2s infinite;
  animation-timing-function: cubic-bezier(0.5, 0.53, 0.32, 0.29);
}

@keyframes loderleft {
  from {
    left: 0%;
  }

  to {
    left: -50%;
  }
}

/* .loader {
    position: relative;
    height: 100vh;
    background: #201e1e;
} */

.loader-bg {
  background-image: url(./img/12333.png);
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  width: 90px;
  height: 90px;
  position: absolute;
  z-index: 3;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.loadercontent {
  content: "";
  background-color: transparent;
  width: 145px;
  height: 145px;
  position: absolute;
  border-radius: 100%;
  z-index: 3;
  top: -32%;
  left: -32%;
  opacity: 0.7;
  box-shadow: #fff -4px -5px 3px -3px;
  transform: translate(-50%, -50%);
  animation: loader 1s infinite;
  animation-timing-function: cubic-bezier(0.5, 0.53, 0.32, 0.29);
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ===============================>Hiring Developer<=============================== */

.hiring-developer {
  margin-top: 180px;
}

.set-input {
  display: flex;
  justify-content: space-between;
}

/* ==================================>our-protfiloyo<================================== */

.ourprotfiloyo-bg {
  background-image: url(./img/ourprotfiloyo.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.ourprotfiloyo-bg h1 {
  color: #fff;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

.ourprotfiloyo-button {
  overflow-x: scroll;
}

.buttondesbal {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin: 10px 20px;
  color: #000;
  font-size: 24px;
  padding: 10px 20px;
}

.ourprotfiloyo-button button:hover {
  background-color: #4d72e2c0 !important;
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px;
}

.buttonaction {
  /* background-color: transparent !important; */
  border: none !important;
  box-shadow: none !important;
  margin: 10px 20px;
  color: #fff;
  font-size: 24px;
  background-color: #4d73e2;
  padding: 10px 20px;
  border-radius: 15px;
  /* font-weight: 600; */
}

.ourprotfiloyo-border-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ourprotfiloyo-content {
  margin-top: 50px;
}

.tab-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tab-card {
  text-align: center;
  width: 33%;
}

.tab-img {
  border-radius: 15px;
  overflow: hidden;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.tab-card h5{
  margin: 20px 0;
}
.tab-img img {
  width: 100%;
  height: 300px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

.tab-img img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

/* ================================>Apply For Job<================================ */

.applyforjob-bg {
  background-image: url(./img/applyforjob.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

/* .applyforjob-contant {
  text-align: center;
} */

.applyforjob-contant h2 {
  margin-bottom: 50px;
  color: #000;
}

.applyforjob-contant p {
  margin-bottom: 25px;
}

.resume-file {
  outline: 2px dashed #999999;
  background-color: #f1f1f1;
  height: 105px;
  text-align: center;
  padding: 35px;
}

/* ====================================================================================>top buttoun in footer by mitesh<========================================================================================== */
.return-to-top {
  border: none !important;
  position: fixed;
  bottom: 10px;
  right: 35px;
  padding: 10px;
  border-radius: 100%;
  /* opacity: 0.7; */
  background-color: #4d73e2;
  display: none;
  /* display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 50px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; */
}

.return-to-top img {
  width: 30px;
}

/* ===================================>Input set<=================================== */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ================ sidebar ============================================================ */
.sidebar {
  position: fixed;
  /* left: -300px; */
  width: 300px;
  height: 100vh;
  background: #354b66;
  transition: 0.5s;
  z-index: 55;
}

.show-sidebar {
  left: 0;
}

.hide-sidebar {
  left: -300px;
}

.bars {
  position: fixed;
  left: 10px;
  font-size: 30px;
  z-index: 555;
}

.show-animated{
  position: relative;
  animation: showanimated 1s;
}
.hide-animated{
  position: relative;
  animation: hideanimated 2s;
}

@keyframes showanimated{
  from {top: 0px;}
  to {top: 200px;}
}

@keyframes hideanimated{
  from {top: 0PX;}
  to {top: -475px;}
}

/* ================ applyjob ============================================================ */
.applyjob {
  /* margin-top: 200px; */
  padding-bottom: 30px;
}

.applyforjob-contant h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 41px;
  color: #1e1e1e;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 48px;
}

.applyforjob-contant p {
  width: 90%;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 30px;
  color: #636363;
  font-size: 17px;
  font-weight: 400;
}

.space-bottom {
  padding-bottom: 50px;
}
.padding-bottom-80px{
  padding-bottom: 80px;
}

.react-player {
  position: fixed;
  bottom: 1px;
  right: 0;
  width: 40%;
  height: 450px;
  z-index: 999;
  background-color: #000;
}

.vedio_close_btn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 99999;
  padding: 5px;
  width: 28px;
  height: 28px;
  background-color: #d6d9d9;
  border-radius: 50%;
}

.vedio_close_btn:hover {
  background-color: #d6d9d9;
  border-radius: 50%;
  padding: 5px;
}
