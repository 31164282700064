#gallery {
  padding-top: 40px;
}

.img-wrapper {
  position: relative;
  margin-top: 15px;
  cursor: pointer;border-radius: 25px;
}

.img-wrapper img {
  width: 100%;
}

.img-overlay {
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;border-radius: 25px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.img-overlay i {
  color: #fff;
  font-size: 3em;
}


#overlay {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#overlay img {
  margin: 0;
  width: 80%;
  height: 95%;
  object-fit: contain;
  padding: 5%;
}


#nextButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
}

#prevButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
}

#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: 15px;
  right: 15px;
}

.Life7seasol-bg {
  background-image: url(../../img/download.png);
  height: 40vh;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}

.Life7seasol-bg h1 {
  color: #fff;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  /* margin-top: 18px; */
  font-weight: 500;
  padding-top: 130px;
}

.life7seasol {
  text-align: center;
}

.life7seasol h3 {
  color: #1e1e1e;
    font-weight: 600;
  margin-bottom: 20px;
  line-height: 45px;
}

.main-event {
  margin-top: 150px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}

/* .parent-desc{
  overflow: hidden;
} */

.parent-desc:nth-child(even) {
  display: flex;
  flex-direction: column-reverse;
}

.imgs-content{
  width: 600px;
  height: 550px;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
}

.imgs-content img{
  width: 600px;
  height: 550px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .6s ease-in-out;
  transition: .6s ease-in-out;
}

.imgs-content img:hover{
  -webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.desc {
  width: 600px;
  margin: 25px 0;
}

.desc h3 {
  color: #1e1e1e;
  font-weight:700;
  /* margin-bottom: 20px; */
  font-size: 1.75rem;
}

.desc p{
  color: #636363;
  margin-bottom: 0px !important;
  font-size: 20px;
}
.event-section-1 img{
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 50px 0 70px 0;
}
.events_cards
{
  margin: 35px 0;
}

.slick-next:before {
  right: 35px;
}

.card-img .video-img .play-btn{
  width: 10% !important;
  position: absolute;
  cursor: pointer;
  box-shadow: none !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-img .video-img img{
  width: 70% !important;
  margin: 0 auto !important;
}

.card-img img{
  width: 75% !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.event-1{
  align-items: center;
}
.slick-arrow.slick-prev
{
  z-index: 1;
  left: 100px;
  top: 50%;
}
.slick-arrow.slick-next{
  z-index: 1;
  right: 100px;
  top: 50%;
}
.slick-next:before{
  content: url(/src/img/chevron-right.svg) !important;
  height: 100%;
  width: 100%;
  opacity: 1 !important;
  position: absolute !important;
  right: 0px;
  top: 0 !important;
  background-color: #4d73e2 !important;
  border-radius: 10px;
  line-height: 0;
}
.slick-prev:before{
  content: url(/src/img/chevron-left.svg) !important;
  height: 100%;
  width: 100%;
  opacity: 1 !important;
  position: absolute !important;
  left: 0px;
  top: 0 !important;
  background-color: #4d73e2 !important;
  border-radius: 10px;
  line-height: 0;
}

