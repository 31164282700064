@media only screen and (max-width: 991px) {
    .pss-container::after{
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .kd-process-steps ul{
      flex-direction: column;
      width: 100%;
    }
  }